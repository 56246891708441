import type { Team } from '@spec/Organization';
import type { Privacy, Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import { findById } from '../lib/ArrayUtils';
import { findChildTeams } from './Organization';
import { getTeamMembers, isEnrolledTalent } from './Talent';

export const calcEmployeeCount = (talents: Talent[]): number =>
    talents.filter(isEnrolledTalent).filter((v) => /^c[ab]/.test(v.employment.employeeCode)).length;

export const calcAverageTenure = (talents: Talent[], currentTime: Date): number => {
    const targets = filterPermanentEmployees(talents);
    let total = 0;
    for (const talent of targets) {
        const diffMonth = dayjs(currentTime).diff(talent.joinedAt, 'month');
        total += diffMonth;
    }
    return total / targets.length;
};

export const calcAverageAge = (
    talents: Talent[],
    privacies: Privacy[],
    currentTime: Date
): number => {
    const targets = filterPermanentEmployees(talents);
    const ages: number[] = [];
    for (const talent of targets) {
        const privacy = privacies.find((v) => v.talentId === talent.id);
        if (privacy?.birthday) {
            const age = dayjs(currentTime).diff(privacy?.birthday, 'year');
            ages.push(age);
        }
    }
    return ages.reduce((acc, val) => acc + val, 0) / ages.length;
};

export const calcFemaleRatio = (talents: Talent[], privacies: Privacy[]): number => {
    const targets = filterPermanentEmployees(talents);
    const sexes: number[] = [];
    for (const talent of targets) {
        const privacy = privacies.find((v) => v.talentId === talent.id);
        if (privacy?.sex) {
            sexes.push(privacy?.sex);
        }
    }
    return sexes.filter((v) => v === 2).length / sexes.length;
};

const filterPermanentEmployees = (talents: Talent[]) =>
    talents.filter((v) => v.employment.employmentStatus === '正社員').filter(isEnrolledTalent);

export const calcFemaleManagersRatio = (talents: Talent[], privacies: Privacy[], teams: Team[]) => {
    const enrolledTalents = talents.filter(isEnrolledTalent);
    const managers = enrolledTalents
        .filter(
            (v) =>
                v.employment.employeeCode.startsWith('cb') ||
                v.employment.employmentStatus === '正社員'
        )
        .filter(
            (v) =>
                isManager(v) || hasLargeTeam(v, enrolledTalents, findById(v.teamId, teams), teams)
        );
    return calcFemaleRatio(managers, privacies);
};

const isManager = (talent: Talent) => {
    if (talent.grade !== null && talent.grade.level >= 4) {
        return true;
    }
    const titles = ['取締役', '執行役員', '董事長'];
    if (titles.some((title) => talent.position.includes(title))) {
        return true;
    }
    return false;
};

const hasLargeTeam = (talent: Talent, talents: Talent[], team: Team, teams: Team[]) => {
    if (talent.position === '') {
        return false;
    }
    const children = findChildTeams(team.id, teams);
    if (children.length >= 3) {
        // children contains the team itself,
        // so the team has at least 2 children.
        return true;
    }
    const members = children
        .map((v) => getTeamMembers(talents, v.id, true).length)
        .reduce((acc, val) => acc + val, 0);
    if (members >= 10) {
        return true;
    }
    return false;
};

export const calcSkilledFemaleRatio = (talents: Talent[], privacies: Privacy[]) => {
    const skilledTalents = filterPermanentEmployees(talents).filter(
        (v) => v.grade !== null && v.grade?.level >= 3
    );
    return calcFemaleRatio(skilledTalents, privacies);
};
