import { Alert, Box, MenuItem, TableCell, TableRow, TextField, Typography } from '@mui/material';
import type { Team } from '@spec/Organization';
import type { Talent } from '@spec/Talent';
import type { DailyBackupResponse } from '@spec/humanCapital';
import dayjs from 'dayjs';
import { useState } from 'react';
import { findChildTeams, generateBreadcrumbs, isAvailableTeam } from '../../domains/Organization';
import {
    calcAverageAge,
    calcAverageTenure,
    calcEmployeeCount,
    calcFemaleManagersRatio,
    calcFemaleRatio,
    calcSkilledFemaleRatio,
} from '../../domains/humanCapital';
import { useForm, useFormText } from '../../lib/Form';
import { useBackupDates, useDailyBackup } from '../../queries/humanCapital';
import { NoItems } from '../NoItems';
import { SortableTableWithCopyButton } from '../SortableTable';
import { UnitText } from '../UnitText';
import { WaitQuery } from '../WaitLoading';
import { ColumnsHelp } from './ColumnsHelp';

interface Row {
    id: string;
    teamName: string;
    averageTenure: number;
    averageAge: number;
    employeeCount: number;
    turnOverRate: number;
    femaleRatio: number;
    femaleManagersRatio: number;
    skilledFemaleRatio: number;
}

const headers: Array<[keyof Row, string]> = [
    ['teamName', '組織名'],
    ['averageTenure', '平均勤続年数'],
    ['averageAge', '平均年齢'],
    ['employeeCount', '従業員数'],
    ['turnOverRate', '社員離職率'],
    ['femaleRatio', '女性比率'],
    ['femaleManagersRatio', '女性管理職比率'],
    ['skilledFemaleRatio', '高度女性人材比率'],
];

const filterMembers = (team: Team, teams: Team[], talents: Talent[]) => {
    const teamIds = findChildTeams(team.id, teams).map((v) => v.id);
    return talents.filter((v) => teamIds.includes(v.teamId));
};

const fixNumber = (value: number) =>
    Number((isNaN(value) || !isFinite(value) ? 0 : value).toFixed(2));

const percentage = (value: number) =>
    Number((isNaN(value) || !isFinite(value) ? 0 : value).toFixed(4));

export const HumanCapital = () => {
    const maybeBackupDates = useBackupDates();
    const [date, setDate] = useState<Date | null>(null);
    return (
        <Box>
            <Alert severity="info">
                まだ下地づくりの段階なのでここに出てくる数字は実際のものと異なります
            </Alert>
            <Box my={2}>
                <ColumnsHelp />
            </Box>
            <WaitQuery query={maybeBackupDates}>
                {({ data }) => <DateSelector dates={data} onSelect={setDate} />}
            </WaitQuery>
            {date !== null && <CapitalLoader date={date} />}
        </Box>
    );
};

const CapitalLoader = (props: { date: Date }) => {
    const dailyBackup = useDailyBackup(props.date);
    return (
        <WaitQuery query={dailyBackup}>
            {({ data }) => <CapitalTable date={props.date} backup={data} />}
        </WaitQuery>
    );
};

const CapitalTable = (props: { date: Date; backup: DailyBackupResponse }) => {
    const currentTime = props.date;
    const { talents, teams, privacies } = props.backup;
    const availableTeams = teams.filter(isAvailableTeam);
    const baseTeams = availableTeams.filter((v) => v.isBaseTeam);
    const rows: Row[] = baseTeams.flatMap((team) => {
        const memberIds = new Set(filterMembers(team, teams, talents).map((v) => v.id));
        if (memberIds.size === 0) {
            return [];
        }
        const members = talents.filter((v) => memberIds.has(v.id));
        return [
            {
                id: generateBreadcrumbs(team.id, teams)
                    .map((v) => v.code ?? '')
                    .join('-'),
                teamName: team.name,
                averageTenure: calcAverageTenure(members, currentTime),
                averageAge: fixNumber(calcAverageAge(members, privacies, currentTime)),
                employeeCount: calcEmployeeCount(members),
                turnOverRate: 0,
                femaleRatio: percentage(calcFemaleRatio(members, privacies)),
                femaleManagersRatio: percentage(
                    calcFemaleManagersRatio(members, privacies, availableTeams)
                ),
                skilledFemaleRatio: percentage(calcSkilledFemaleRatio(members, privacies)),
            },
        ];
    });
    return (
        <Box>
            <Typography mt={2}>{dayjs(currentTime).format('YYYY年M月D日現在')}</Typography>
            <Box my={2}>
                <SortableTableWithCopyButton
                    stickyHeader
                    headers={headers}
                    defaultSortKey="id"
                    defaultSortDirection="asc"
                    secondarySortKey="id"
                    rows={rows}
                    serializeRow={(row) => [row.id, row.averageTenure, row.employeeCount]}
                >
                    {(row) => (
                        <TableRow hover>
                            <TableCell>{row.teamName}</TableCell>
                            <TableCell align="right">
                                {Math.floor(row.averageTenure / 12)}
                                <UnitText text="年 " />
                                {(row.averageTenure % 12).toFixed(2)}
                                <UnitText text="ヶ月" />
                            </TableCell>
                            <TableCell align="right">
                                {row.averageAge.toFixed(2)}
                                <UnitText text="歳" />
                            </TableCell>
                            <TableCell align="right">
                                {row.employeeCount}
                                <UnitText text="人" />
                            </TableCell>
                            <PercentCell value={row.turnOverRate} />
                            <PercentCell value={row.femaleRatio} />
                            <PercentCell value={row.femaleManagersRatio} />
                            <PercentCell value={row.skilledFemaleRatio} />
                        </TableRow>
                    )}
                </SortableTableWithCopyButton>
            </Box>
        </Box>
    );
};

const PercentCell: React.FC<{ value: number }> = (props) => (
    <TableCell align="right">
        {(props.value * 100).toFixed(1)}
        <UnitText text="%" />
    </TableCell>
);

const DateSelector = (props: { dates: Date[]; onSelect: (v: Date | null) => void }) => {
    const dates = props.dates
        .map(dayjs)
        .toSorted((a, b) => b.unix() - a.unix())
        .filter(
            (date, index, dates) =>
                dates.findIndex((v) => v.format('YYYY-MM') === date.format('YYYY-MM')) === index
        );
    const form = useForm({
        date: useFormText('-'),
    });
    if (dates.length === 0) {
        return <NoItems>バックアップが存在しません</NoItems>;
    }
    return (
        <Box>
            <TextField
                select
                value={form.date.value}
                onChange={(e) => {
                    const date = dayjs(e.target.value);
                    const v = date.isValid() ? date.toDate() : null;
                    form.date.setValue(e.target.value);
                    props.onSelect(v);
                }}
            >
                <MenuItem value="-">選択してください</MenuItem>
                {dates.map((v) => (
                    <MenuItem key={v.format('YYYYMMDD')} value={v.format('YYYYMMDD')}>
                        {v.format('YYYY年M月D日')}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};
